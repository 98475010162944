@import '../../../../styles/colors';

.title {
  text-align: center;
  padding-top: 4vh;
  @media screen and (max-width: 576px) {
    text-align: center;
    max-width: 288px;
    padding: 3vh 18% 9%;
  }
}
.container {
  @media screen and (max-width: 576px) {
    justify-content: space-between;
    flex-direction: column;
    display: flex;
  }
}
.buttonsRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 10px;
}
.printText {
  font-family: 'Mulish';
  color: $blue;
  text-align: right;
}
.buttonsContainer {
  flex-direction: column;
}
.wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 53px;
  .blueTitle {
    font-weight: 700;
    font-family: 'Mulish';
    color: $blue;
  }
  .purposeText {
    font-weight: 700;
    font-family: 'Mulish';
    color: $blue;
    font-size: 20px;
    margin-bottom: 20px;
    @media screen and (max-width: 576px) {
      margin-bottom: 33px;
    }
  }
  .fullName {
    font-weight: 700;
    font-family: 'Mulish';
  }
  .greyBoldText {
    font-weight: 700;
    font-family: 'Mulish';
    color: $darkGrey;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  .divider {
    width: 100%;
    height: 1px;
    background-color: $darkGrey;
  }
  .memberInfoColumn {
    margin-right: 20px;
  }
  .infoRow {
    margin-top: 7px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 20px;
    gap: 10px;
    @media screen and (max-width: 576px) {
      gap: 10px;
      row-gap: 0;
    }
  }
  .memberInfoTitle {
    font-weight: 700;
    font-family: 'Mulish';
    color: $darkGrey;
  }
  .row {
    display: flex;
    flex-direction: row;
  }
  .tipContainer {
    background-color: $white;
    opacity: 1;
    border-radius: 0;
    margin-left: 10px;
    opacity: 1 !important;
    filter: drop-shadow(0 0 0.4rem rgb(212, 211, 211));
  }
  .suggestedTimeContainer {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-top: -10px;
    margin-bottom: -10px;
  }
  .suggestedTimeText {
    font-size: 12px;
    font-weight: 700;
    color: $orange;
    text-align: left;
    font-family: 'Mulish';
    margin-right: 10px;
  }
  .returnPickUpTimeText {
    font-size: 16px;
    font-weight: 700;
    color: $orange;
    margin-left: 8px;
    font-family: 'Mulish';
    margin-right: 10px;
  }
  .locationRow {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .tipContentText {
    font-weight: 400;
    font-family: 'Mulish';
    font-size: 12px;
    color: $darkGrey2;
  }
  .tipContentTimeText {
    font-weight: 400;
    font-family: 'Mulish';
    font-size: 12px;
    color: $orange;
  }
  .tipContentTextBold {
    font-weight: 700;
    font-family: 'Mulish';
    font-size: 12px;
    color: $darkGrey2;
  }
  .returnTipText {
    font-family: 'Mulish';
    font-weight: 700;
  }
  .memberInfoValue {
    font-family: 'Mulish';
    font-size: 16px;
    margin-bottom: 20px;
    @media screen and (max-width: 576px) {
      max-width: 130px;
      margin-bottom: 10px;
    }
  }
  .tripSection {
    display: flex;
    padding-left: 10px;
    padding-top: 10px;
    flex-direction: row;
    margin-bottom: 20px;
  }
  .tripIcon {
    filter: drop-shadow(0 0 0.4rem rgb(212, 211, 211));
  }
  .locationName {
    font-size: 16px;
    font-weight: bold;
    font-family: 'Mulish';
  }
  .willCallText {
    font-size: 16px;
    font-weight: bold;
    font-family: 'Mulish';
    margin-top: 20;
  }
  .locationsContainer {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    justify-content: space-between;
  }
  .regularText {
    font-family: 'Mulish';
    font-size: 16px;
  }
  .infoContainer {
    padding-left: 30px;
    padding-right: 30px;
  }
  @media screen and (max-width: 576px) {
    padding: 0 2vw 0 3vw;
    .suggestedTimeText {
      max-width: 180px;
      margin-right: 0;
    }
  }
}
.noteText,
.noteText2 {
  text-align: center;
  font-size: 22px;
  color: $green;
  padding: 4vw 4vw 0;
  margin: 0;
  @media screen and (max-width: 576px) {
    padding: 4vw 6vw 0;
  }
}
.noteText2 {
  padding: 0 4vw !important;
  @media screen and (max-width: 576px) {
    padding: 0 10vw !important;
  }
}
.referenceIdStyle {
  font-weight: 600;
font-size: 20px;
line-height: 24px;
margin: 0;
padding-top: 4vh;
span {
  font-weight: 700;
}
}
